
import {get,post} from "@/axios";

//订单模块
export function getOrderList(data) {
    return post('/index.php?module=transactions&action=list',data);
}


export function postEdit(data) {//提交编辑价格
    return post('/index.php?module=transactions&action=edit',data);
}

export function getEmailContent(data) {//获取邮件详情
    return get('/index.php?module=transactions&action=email_template',data);
}

export function send_email(data) {//批量发送邮件
    return post('/index.php?module=transactions&action=send_email',data);
}
export function batch_edit(data) {//批量编辑价格
    return post('/index.php?module=transactions&action=batch_edit',data);
}

export function export_excel(data) {//批量编辑价格
    return post('/index.php?module=transactions&action=export_excel',data);
}

<template>
  <div>
    <div class="mainContent">
      <a-form-model :layout="layout" style="width:100%;" ref="ruleForm" :model="form">
        <a-col :sm="24" :md="12" :lg="8" :xl="6">
          <a-form-model-item label="货币名称" colon :labelCol='labelcol' :wrapperCol="wrappercol" prop="currency">
            <a-input v-model="form.currency" placeholder="请输入货币名称..." />
          </a-form-model-item>
        </a-col>
        <a-col :sm="24" :md="12" :lg="8" :xl="6">
          <a-form-model-item label="客户邮箱" colon :labelCol='labelcol' :wrapperCol="wrappercol" prop="email">
            <!-- <a-input v-model="form.email" placeholder="请输入客户邮箱..." /> -->
            <a-textarea
              v-model:value="form.email"
              placeholder="请输入客户邮箱..."
              :auto-size="{ minRows: 1, maxRows: 10 }"
            />
          </a-form-model-item>
        </a-col>
        <!-- 
        <a-col :sm="24" :md="12" :lg="8" :xl="6">
          <a-form-model-item label="域名" colon :labelCol='labelcol' :wrapperCol="wrappercol" prop="domain">
            <a-input v-model="form.domain" placeholder="请输入域名..." />
          </a-form-model-item>
        </a-col>
        <a-col :sm="24" :md="12" :lg="8" :xl="6">
          <a-form-model-item label="分组名称" colon :labelCol='labelcol' :wrapperCol="wrappercol" prop="group_name">
            <a-input v-model="form.group_name" placeholder="请输入分组名称..." />
          </a-form-model-item>
        </a-col>


          <a-col :sm="24" :md="12" :lg="8" :xl="6">
            <a-form-model-item label="订单状态" :labelCol='labelcol' :wrapperCol="wrappercol" prop="status">
              <a-select v-model="form.status" placeholder="请选择订单状态..." >
                <a-select-option value="">
                  全部
               </a-select-option>
                <a-select-option value="success">
                   成功
                </a-select-option>
                <a-select-option value="failed">
                   失败
                </a-select-option>
                <a-select-option value="unpaid">
                  未付款
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :sm="24" :md="12" :lg="8" :xl="6">
            <a-form-model-item label="支付方式" :labelCol='labelcol' :wrapperCol="wrappercol" prop="payment">
              <a-select v-model="form.payment" placeholder="请选择支付方式...">
                <a-select-option value="">
                  全部
               </a-select-option>
                <a-select-option value="paypal">
                  paypal
                </a-select-option>
                <a-select-option value="stripe">
                  stripe
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col> -->

          <a-col :sm="24" :md="12" :lg="8" :xl="6"  >
            <a-form-model-item label="开始日期" :labelCol='labelcol' :wrapperCol="wrappercol" >
              <a-date-picker
              v-model="form.start_time"
              :show-time="{defaultValue: moment(new Date(), 'YYYY-MM-DD HH:mm:ss').endOf('day') }" format="YYYY-MM-DD HH:mm:ss" @change="onChangeStart" @ok="onOk"/>
            </a-form-model-item>
          </a-col>
          <a-col :sm="24" :md="12" :lg="8" :xl="6"  >
            <a-form-model-item label="结束日期" :labelCol='labelcol' :wrapperCol="wrappercol" >
              <a-date-picker
              v-model="form.end_time"
              format="YYYY-MM-DD HH:mm:ss"
              :show-time="{ defaultValue: moment(new Date(), 'YYYY-MM-DD HH:mm:ss').endOf('day') }" @change="onChangeEnd"/>
            </a-form-model-item>
          </a-col>
           <a-col :sm="24" :md="12" :lg="8" :xl="6">
          <a-form-model-item label="价格区间" colon :labelCol='labelcol' :wrapperCol="wrappercol" prop="email">
            <!-- <a-input v-model="form.email" placeholder="请输入客户邮箱..." /> -->
            <a-textarea
              v-model:value="total_areaMin"
              placeholder="请输最小价格..."
              style="max-width: 49%; margin-right: 2%;"
              :auto-size="{ minRows: 1, maxRows: 10 }"
            />
            <a-textarea
              v-model:value="total_areaMax"
              style="max-width: 49%;"
              placeholder="请输最大价格..."
              :auto-size="{ minRows: 1, maxRows: 10 }"
            />
          </a-form-model-item>
        </a-col>
        <a-col :sm="24" :md="12" :lg="8" :xl="6">
          <a-form-model-item label="折扣区间" colon :labelCol='labelcol' :wrapperCol="wrappercol" prop="email">
            <a-textarea
              v-model:value="discount_areaMin"
              placeholder="请输最小价格..."
              style="max-width: 49%; margin-right: 2%;"
              :auto-size="{ minRows: 1, maxRows: 10 }"
            />
            <a-textarea
              v-model:value="discount_areaMax"
              style="max-width: 49%;"
              placeholder="请输最大价格..."
              :auto-size="{ minRows: 1, maxRows: 10 }"
            />
          </a-form-model-item>
        </a-col>

          <a-col :sm="24" :md="12" :lg="8" :xl="6" class="lyq_flex_grow">
            <a-form-model-item style="text-align:right;">
              
              <a-button type="primary" @click="search">
                搜索
              </a-button>
              <a-button @click="reset('ruleForm')">重置</a-button>

            </a-form-model-item>
          </a-col>
      </a-form-model>
    </div>
    <!-- <div class="mainContent" style="display: flex;justify-content: flex-end;">
      <a-button type="primary" @click="onAdd">
        添加用户
      </a-button>
    </div> -->
    <div class="mainContent" >

      <div class="tableHeader">
        <div class="title" style="word-break: keep-all;">查询表格</div>
        
        <div class="operate">
          <a-button type="primary" @click="exportData" :loading='isExport'>
            导出
          </a-button>
          <!-- 下面一个按钮设置了气泡，这个没设置的话，触发disabled时会一前一后的 -->
          <a-popconfirm
            disabled
          >           
            <a-button type="primary"  @click="batchSetPrice" :disabled='selectedRowKeys.length<=0'>
              批量设置价格
            </a-button>
          </a-popconfirm>

          <a-popconfirm
            :title='emailLength'
            ok-text="确认"
            cancel-text="取消"
            @confirm="bulkSendMail"
            :disabled='selectedRowKeys.length<=0'
          >
            <a-button type="primary" :disabled='selectedRowKeys.length<=0'>
              批量发送邮件
            </a-button>
          </a-popconfirm>


          <a-tooltip placement="top">
            <template slot="title">
              <span>下载</span>
            </template>
            <a class="ant-dropdown-link" style="display:block;margin-right:10px;" @click="start" :disabled="!selectedRowKeys.length > 0">
              <a-icon type="vertical-align-bottom" />
            </a>
          </a-tooltip>

          <a-tooltip placement="top">
            <template slot="title">
              <span>表格大小</span>
            </template>
            <a-dropdown :trigger="['click']" style="margin-right:10px;">
              <a class="ant-dropdown-link" style="display:block">
                <a-icon type="column-height" />
              </a>
              <a-menu slot="overlay">
                <a-menu-item>
                  <a @click="tableSize='default'">默认</a>
                </a-menu-item>
                <a-menu-item>
                  <a @click="tableSize='middle'">中等</a>
                </a-menu-item>
                <a-menu-item>
                  <a @click="tableSize='small'">紧凑</a>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </a-tooltip>

        </div>
      </div>

      <a-table
        :size="tableSize"
        :scroll="{ x: true }"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :columns="columns"
        :row-key="record => record.id"
        :data-source="data"
        :pagination="pagination"
        :loading="loading"
        bordered
        @change="handleTableChange"
      >

        <!-- 地址 -->
        <template slot="state" slot-scope="data,record">
          <div style='display: inline-block'>
            <span class="spanItem"><span class='itemLabel'>州/省</span>：<span class='itemContent'>{{record.address}}</span></span>
            <!-- <span class="spanItem"><span class='itemLabel'>城市</span>：<span class='itemContent'>{{record.city}}</span></span>
            <span class="spanItem"><span class='itemLabel'>街道</span>：<span class='itemContent'>{{record.address}}</span></span>
            <span class="spanItem"><span class='itemLabel'>邮编</span>：<span class='itemContent'>{{record.zip_code}}</span></span> -->
          </div>
        </template>
        <!-- 催单链接 -->
        <template slot="reminder_link" slot-scope="data,record">
          <div style='display: flex'>
            <a :href="record.reminder_link" target="_blank">
              {{record.reminder_link}}               
            </a>             
          </div>
        </template>
        <!-- 下单时间 -->
        <template slot="create_time" slot-scope="data,record">
          <div style='min-width:80px;'>
            {{record.create_time}}                  
          </div>
        </template>
        <!-- email -->
        <template slot="email" slot-scope="data,record">
          <div style='max-width:160px;'>
            {{record.email}}                  
          </div>
        </template>
        <!-- 地址 -->
        <template slot="address" slot-scope="data,record">
          <div style='min-width:100px;'>
            {{record.address}}                  
          </div>
        </template>
        <!-- ip地址 -->
        <template slot="ip_address" slot-scope="data,record">
          <div style='max-width:160px;'>
            {{record.ip_address}}                  
          </div>
        </template>
        
        <!-- 操作单元格 -->
        <template slot="操作" slot-scope="data">

              <span>
                <a-button size="small" type="primary"  @click="onCheck(data)">商品详情</a-button>              
              </span>

        </template>

      </a-table>

      <a-modal v-model="productVisible" title="商品信息" @ok="productVisible=false" width="900px">
        <a-table
        :size="tableSize"
        :columns="productColumns"
        :row-key="record => record.id"
        :data-source="productList.products"
        :pagination='false'
        bordered
        >

          <!-- 产品图片 -->
          <template slot="goods_image" slot-scope="data,record">
            <!-- <div style='display: flex'>
              <a :href="record.product_url" target="_blank">
                {{record.goods_image}}               
              </a>             
            </div> -->
            <img class='lyqBg' :src='record.goods_image' @click='enlarge(record.goods_image)'>
          </template>

        </a-table>
        <div class='inputBox'>
          <div style="margin-right:3rem">
            <span style='font-weight: bold;'>订单总价：</span>        
              {{productList.total}} {{productList.currency}} 
          </div>
          <div>
            <span style='font-weight: bold;'>客户邮箱：</span>        
            {{productList.email}}
          </div>
        </div>   

        <div class='inputBox'>
          <span style='font-weight: bold;'>折扣价：</span>        
          <a-input width='100px'
            v-model.number="editDiscount.discount"
          />
          <a-button  type="primary"  @click="onSave" style="margin-right:16px;">提交</a-button>   
          <a-button  type="primary"  @click="onEmailDetails">邮件详情</a-button>  
        </div>       
      </a-modal>

      
      <a-modal v-model="imgVisible" title="产品图片" :footer="null" width="620px">
        <img class='enlargeImg' :src='enlargeImgUrl' >
      </a-modal>

      <a-modal v-model="batchSetPriceVisible" title="批量设置价格"  @ok="postbatchSetPrice" width="400px">
        <div class='inputBox' style='justify-content: center;margin:0 auto'>
          <span style='font-weight: bold;'>折扣价：</span>        
          <a-input v-model.number="batchSetPriceValue"/>
        </div> 
        <div class='inputBox' style='justify-content: center;margin:0 auto'>
          <span style='font-weight: bold;'>折扣率：</span>        
          <a-input v-model.number="batchSetPriceRate"/>
        </div> 
      </a-modal>

      <a-modal v-model="emailVisible" title="邮件详情"  width="900px" >
        <pre style='max-height: 66vh;'>{{emailContent}}</pre>
        <template slot="footer">
          <a-button  type="primary"  @click="copyEmail">
            复制
          </a-button>
        </template>
      </a-modal>


    </div>


  </div>
</template>
<script>
import {getOrderList,postEdit,getEmailContent,send_email,batch_edit,export_excel} from "@/axios/order.js"
import {myMixin} from "@/static/js/mixin.js"
import moment from 'moment';
const productColumns = [//产品列描述
  {
    align:'center',
    title: '订单ID',
    dataIndex: 'order_id',
  },
  
  {
    align:'center',
    title: '商品名称',
    dataIndex: 'name',
  },
  {
    align:'center',
    title: '数量',
    dataIndex: 'quantity',
  },
  {
    align:'center',
    title: '产品图片',
    dataIndex: 'goods_image',
    scopedSlots: { customRender: 'goods_image', },
  },
  // {
  //   align:'center',
  //   title: '编辑',
  //   // dataIndex: 'status',
  //   scopedSlots: { customRender: '编辑', },
  // },
];
const columns = [//列描述
  {
    align:'center',
    title: '商户名称',
    dataIndex: 'merchant',
    sorter: (a, b) => {
      return a.merchant.localeCompare(b.merchant, 'zh-CN');
    }
  },
  {
    align:'center',
    title: '姓',
    dataIndex: 'last_name',
  },
  {
    align:'center',
    title: '名',
    dataIndex: 'first_name',
  },
  {
    align:'center',
    title: '下单网站',
    dataIndex: 'site',
  },
  {
    align:'center',
    title: '下单时间',
    dataIndex: 'create_time',
    scopedSlots: { customRender: 'create_time', },
    sorter: (a, b) => {
      let aa=new Date(a.create_time)
      let bb=new Date(b.create_time)
       return aa < bb ? -1:aa > bb ? 1:0

    }
  },
  {
    align:'center',
    title: '国家',
    dataIndex: 'country',
  },
  {
    align:'center',
    title: '货币',
    dataIndex: 'currency',
  },
  {
    align:'center',
    title: '订单金额',
    dataIndex: 'total',
    sorter: (a, b) => a.total - b.total,
  },
  {
    align:'center',
    title: '折扣金额',
    dataIndex: 'products[0].discount',
    sorter: (a, b) => a.products[0].discount - b.products[0].discount,
  },
    {
    align:'center',
    title: 'Email',
    dataIndex: 'email',
    scopedSlots: { customRender: 'email', },
  },

  {
    align:'center',
    title: '地址',
    dataIndex: 'address',
    scopedSlots: { customRender: 'address', },
  },
  {
    align:'center',
    title: 'IP地址',
    dataIndex: 'ip_address',
    scopedSlots: { customRender: 'ip_address', },
  },
  {
    align:'center',
    title: '催单链接',
    dataIndex: 'reminder_link',
    scopedSlots: { customRender: 'reminder_link', },
  },
  {
    align:'center',
    title: '操作',
    // dataIndex: 'status',
    scopedSlots: { customRender: '操作', },
  },

];
export default {
  filters: {

    statusFilter(status) {
      const statusMap = {
        success:'成功',
        failed :'失败',
        unpaid:'未支付',
      }

      return statusMap[status]

    },
  },
  mixins: [myMixin],
  data() {

    return {
      productColumns,
      productList:[],
      productVisible:false,
      form: {
        page_num: 10,
        page:1,
        // domain:'',
        // group_name: '',
        // status: '',
        email:'',
        // payment:'',
        currency:'',
        start_time:'',
        end_time: '',
      },

      data: [],//数据
      selectedRowKeys: [],//选择的表格
      pagination: {
        showQuickJumper:true,//是否快速跳转某页
        showSizeChanger:true,//是否可以改变条数
        pageSizeOptions:['10', '20', '50', '100','200','500','1000'],
        showTotal:(total, range) => `当前显示第${range[0]}-${range[1]}，总条数：${total} `
      },//分页器
      loading: false,//是否加载中
      btnLoading:false,
      columns,//列描述
      tableSize:'middle',//表格尺寸

      editDiscount:{
        transaction_id:null,
        discount:null,
      },
      imgVisible:false,
      enlargeImgUrl:'',

      emailVisible:false,
      emailContent:'',

      batchSetPriceVisible:false,
      batchSetPriceValue:0,
      batchSetPriceRate:0,
      total_areaMin:null,
      total_areaMax:null,
      discount_areaMin:null,
      discount_areaMax:null,
      isExport:false,
    }
    
  },
  created(){
    let that=this
    this.form.start_time=this.moment(new Date()).subtract(1, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss')
    this.form.end_time=this.moment(new Date()).subtract(1, 'days').endOf('day').format('YYYY-MM-DD HH:mm:ss')

    this.fetch();

  },
  mounted() {

  //  console.log( this.moment(new Date(), 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),'1111')
  },
  methods:{
      //导出
      async exportData(){
        this.isExport=true
        let data=await export_excel({ ...this.form })
        this.isExport=false
        if(data.status==1){
          const url = data.data
                const link = document.createElement('a')
                link.href = url
                let fileName=data.data.slice(data.data.lastIndexOf("/")+1)
                link.setAttribute(
                  'download',
                  fileName
                )
                document.body.appendChild(link)
                link.click()
        }else{
          this.$message.error(
              data.errmsg,
          );
        }


        // window.location.href = data.data;
      },
      //提交批量设置价格
      postbatchSetPrice(){

        batch_edit({transaction_id:this.selectedRowKeys.join(','),discount:this.batchSetPriceValue,discount_rate:this.batchSetPriceRate}).then((res)=>{
          if(res.status==1){
            this.batchSetPriceVisible=false
            this.fetch();
            this.$message.success(
              '设置价格成功',
            );
          }else{
            this.$message.error(
              '设置价格失败',
            );
          }
        
        })
      
        
      },
      batchSetPrice(){
          this.batchSetPriceVisible=true;
          this.batchSetPriceValue=0;
          this.batchSetPriceRate=0;
      },
      //批量发送邮件
      bulkSendMail(){

          send_email({transaction_id:this.selectedRowKeys.join(',')}).then((res)=>{
            if(res.status==1){
            this.$message.success(
              '邮件发送成功',
            );
            }else{
              this.$message.error(
                '邮件发送失败',
              );
            }
          
          })
      },
      enlarge(url){
        this.enlargeImgUrl=url
        this.imgVisible=true;
      },
      onCheck(data){
        this.productList=data
        this.editDiscount.transaction_id=data.id
        this.editDiscount.discount=data.products[0].discount
        this.productVisible=true;

      },

      onSave(){

        postEdit({...this.editDiscount}).then((res)=>{
          console.log(res,'res')
          let data=this.data.filter(item=>item.id==this.editDiscount.transaction_id)
          console.log(data,'data')
          data[0].products[0].discount=this.editDiscount.discount
          console.log( data[0].products[0].discount,'data')
        })
      },
      onEmailDetails(){//邮件详情
        getEmailContent({transaction_id:this.editDiscount.transaction_id}).then((res)=>{
          if(res.status==1){
            this.emailContent=res.data
            this.emailVisible=true
          }else{
            this.$message.error(
              res.errmsg,
            );
          }
         
        })
        
      },
      async copyEmail(){
          try {
            await navigator.clipboard.writeText(this.emailContent)
            this.$message.success(
              '复制成功',
            );
          } catch (err) {          
            this.$message.error(
              err||'复制失败，请手动复制',
            );
          }

      },
      onOk(){},
      moment,
      search(){//搜索
        this.form.total_area = this.total_areaMin+','+this.total_areaMax;
        this.form.discount_area = this.discount_areaMin+','+this.discount_areaMax;
        this.pagination.current =1;
        this.form.page=1;
        this.fetch();
      },
      onChangeStart(date, dateString) {
        this.form.start_time=dateString;
      },
      onChangeEnd(date, dateString) {
        this.form.end_time=dateString;
      },
      reset(formName){
        this.$refs[formName].resetFields();
        this.form.start_time=this.moment(new Date()).subtract(1, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.form.end_time=this.moment(new Date()).subtract(1, 'days').endOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.pagination.current =1;
        this.form.page=1
        this.fetch();
      },
      onSelectChange(selectedRowKeys) {//选择表格
        this.selectedRowKeys = selectedRowKeys;
      },

      start() {//下载1
        console.log("导出",this.selectedRowKeys)
        // ajax request after empty completing
        setTimeout(() => {
          this.selectedRowKeys = [];
        }, 1000);
      },

      handleTableChange(pagination, filters, sorter) {
        //pagination:分页信息，filters：筛选信息，sorter：排序信息
        this.pagination = { ...pagination };
        this.form.page_num=pagination.pageSize
        this.form.page=pagination.current
        console.log(this.pagination,pagination)

        this.fetch({
          sortField: sorter.field,
          sortOrder: sorter.order,
          ...filters,
        });
      },

      async fetch (params = {}) {
        console.log(this.pagination,'this.pagination')
        this.loading = true;
        let data=await getOrderList({...params,...this.form})
        this.selectedRowKeys=[];
        this.loading = false;
        const pagination = { ...this.pagination };
        pagination.total = data.data.total_num-0;
        this.data = data.data.list;
        this.ordersTotal=data.data.statistics
        this.pagination = pagination;
      },
      

    },
    computed:{
      emailLength(){          
        return `确认要发送 ${this.selectedRowKeys.length} 封邮件吗?`
      } 


    }

    
};
</script>
<style lang="scss" scoped>
@import "@/static/css/index.scss";
.operate .ant-btn{
  margin-right:16px;
  padding:0 8px;
}
.ant-table td { white-space: nowrap; }
.ant-table td .greenBtn{background-color:#7ac141;border-color:#7ac141;}
.ant-table td .spanItems{display: inline-flex;}
.ant-table td .spanItem{display: flex;width:100%;}
.ant-table td .spanItem .itemLabel{text-align-last: justify;display: inline-block;width:46px;min-width:46px;margin-right: 4px;}
.ant-table td .spanItem .itemContent{max-width:150px;display: inline-block;text-align: left;}
.ordersTotal{font-weight:bold;margin:0 20px; display: flex;flex-wrap: wrap;width:100%;justify-content: flex-end;}
.ordersTotal span{width:150px;}
.ant-tag{margin-right:0}

::v-deep .ant-modal-body tr td:nth-child(2)
  {
    min-width:150px;
  }
::v-deep .ant-modal-body tr td img
  {
    width:100px;
    min-height:80px;
    display: inline-block;
    cursor: zoom-in;
    position: relative;
  }
  ::v-deep .ant-modal-body tr td img::after
  {
    width:100px;
    min-height:80px;
    content: '';
    position: absolute;
    background-image: url('../../static/img/456.jpg');
    background-size: 100%;
    left: 0;
    top: 0;
  }
  .inputBox{
    display: flex;align-items: center;margin: 20px 0 0;
  }
  .inputBox .ant-input{width:100px;margin-right: 12px;}
  .enlargeImg{width:100%;}
.ant-input{font-size:12px;}
</style>